<template>
  <div class="content row">
    <div class=" col-sm-12">
        <router-link :to="{name: 'Artist', params: {slug: video.slug} }" style="font-size: 1rem;margin: 0.7rem;">
          <div v-if="video.metadata.videoid" style="min-height: 300px">
            <video-card :video-id="video.metadata.videoid"
                        autoplay
                        width="100%"
                        style="padding: 10px;"
                        mtv
                        height="240px"
                        :title="video.metadata.videotitle"
                        :caption="video.metadata.videocaption">
            </video-card>
          </div>
        </router-link>
    </div>
    <h4 class="tulum-party-subtitle text-smart">Featured Videos</h4>
    <div class="row">
      <div v-for="(card, idx) in featured" :key="card.title" class="col-sm-4">
        <DynamicCard :slug="card.slug" title="" :key="idx"></DynamicCard>
      </div>
    </div>
    <h4 class="tulum-party-subtitle text-smart">You may be interested</h4>
    <div class="row">
      <div v-for="(card, idx) in suggestions" :key="card.title" class="col-sm-4" style="min-height: 200px">
        <DynamicCard :slug="card.slug" title="" :key="idx"></DynamicCard>
      </div>
    </div>
    <hr>
  </div>

</template>

<script>
import VideoCard from "@/components/Cards/VideoCard";
import DynamicCard from "@/components/Cards/DynamicCard";

export default {
  name: 'TulumVideo',


  metaInfo() {
    let videoName = 'TULUM MUSIC VIDEO: ' + this.video.metadata.videotitle || '';
    return {
      title: videoName,
      meta: [
        {
          name: 'description',
          content: `Curated Tulum Related Content`,
        },
        {
          name: 'keywords',
          content: `Curated DJ live set videos @ Tulum Mexico`,
        },
      ],
    };
  },
  props: ['slug', 'klass'],
  data: () => {
    return {

    };
  },
  components: {
    DynamicCard,
    VideoCard,
  },
  created: function () {
    if(this.klass === 'djs') this.$store.dispatch('getArtistVideo', {slug: this.slug});
    this.$store.dispatch('getTulumTv', {start: 0});
  },
  computed: {
    video: {
      get() {
        return this.$store.state.artistVideo;
      },
    },

    featured: {
      get() {
        return this.$store.state.tulumVideos.featured;
      },
    },
    suggestions: {
      get() {
        return this.$store.state.tulumVideos.suggestions;
      },
    },

  }
}
</script>

